

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/avocat-droit-affaires-commercial-corporatif-quebec.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const ContentPage21 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Trouvez des avocats en droit des affaires commercial et corporatif dans votre région! - Soumissions Avocat"
        description="Trouvez le meilleur avocat pour vous pour vous représenter/conseiller pour votre cause en droit corporatif: commerce électronique commerce international convention d’actionnaires constitution de compagnie droit agricole- financement d’entreprise franchise fusion/vente d’entreprise médiation commerciale/arbitrage placement/transfert d’actifs ou actions propriété intellectuelle/marque de commerce relations publiques responsabilité professionnelle autre   Le monde des LIRE PLUS"
        image={LeadImage}>
        <h1>Trouvez des avocats en droit des affaires commercial et corporatif dans votre région!</h1>

					
					
						<p><strong>Trouvez le meilleur avocat pour vous pour vous représenter/conseiller pour votre cause en droit corporatif:</strong></p>
<ul>
<li>commerce électronique</li>
<li>commerce international</li>
<li>convention d’actionnaires</li>
<li>constitution de compagnie</li>
<li>droit agricole- financement d’entreprise</li>
<li>franchise</li>
<li>fusion/vente d’entreprise</li>
<li>médiation commerciale/arbitrage</li>
<li>placement/transfert d’actifs ou actions</li>
<li>propriété intellectuelle/marque de commerce</li>
<li>relations publiques</li>
<li>responsabilité professionnelle</li>
<li>autre</li>
</ul>

<p>Le monde des affaires commercial et celui du droit sont intrinsèquement liés, et ce, depuis déjà plusieurs années. L’un ne saurait survire sans l’autre et c’est pourquoi les dirigeants d’entreprise ont de plus en plus besoin de conseils juridiques pour bien diriger leur entreprise vers leurs objectifs. Loin de se limiter à la plaidoirie, le champ d’aptitudes des avocats d’affaires s’étend également aux domaines propres à la gestion de compagnie, tels que la rédaction de contrats, les disputes d’actionnaires et la restructuration d’entreprise.</p>
<p><StaticImage alt="avocat-droit-affaires-commercial-corporatif-quebec" src="../images/avocat-droit-affaires-commercial-corporatif-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Ce n’est là que le début de l’histoire! Leur offre de service s’adapte aux particularités de l’entreprise qui retient les services d’un avocat! Nous vous présentons donc les domaines pour lesquels les avocats d’affaires proposent d’offrir assistance!</p>
<h2>Non-respect d’un contrat signé : sachez à quoi vous vous engagez!</h2>
<p>Le contrat est l’outil de base de toute relation d’affaires. Il sert à lier les parties à ce dernier en donnant une valeur légale à l’entente créée. Cette valeur légale entraîne des obligations de la part de chacun des partis, en plus de créer des conséquences en fonction du type de contrat formé! Vous devez donc connaître les bases d’un contrat avant de vous embarquer dans ce dernier, puisqu’il existe bien ententes hors desquels aucun avocat ne pourra vous sortir.</p>
<p><strong>Comment se forme un contrat?</strong> Beaucoup trop d’individus et même de dirigeants d’entreprises de toute taille croient toujours, à tort, qu’un contrat constitue un morceau de papier arborant termes, conditions et signatures. Bien que ce bout de papier soit la preuve de l’entente, il n’est en aucun cas le contrat lui-même!</p>
<p>Un contrat se forme dès que l’un des partis accepte la proposition de l’autre de façon claire, sans équivoque et sans changer les termes de l’offre, et ce, même si l’entente est <strong>verbale</strong>! Vous avez bien lu, le contrat se forme dès l’échange de consentement des parties, sans aucune formalité nécessaire (sauf pour les exceptions prévues explicitement par la loi). Voici ce que le Code civil du Québec stipule au sujet de la formation des contrats :</p>
<table>
<tbody>
<tr>
<td>
<p><strong> </strong>Le contrat se forme par le seul échange de consentement entre des personnes capables de contracter, à moins que la loi n’exige, en outre, le respect d’une forme particulière comme condition nécessaire à sa formation, ou que les parties n’assujettissent la formation du contrat à une forme solennelle.</p>
<p> Il est aussi de son essence qu’il ait une cause et un objet.</p>
<p>
Article 1385 C.c.Q.</p>
</td>
</tr>
</tbody>
</table>
<p>Ainsi, il se peut très bien que vous vous soyez engagé dans un contrat quelconque, et ce, de façon verbale! Vous serez donc tenus aux mêmes obligations qu’engendre un contrat mis par écrit, il sera simplement plus difficile pour votre cocontractant d’en faire la preuve en cour!</p>
<p>À ce titre, tous les contrats n’entraînent pas le même degré d’obligations. En plus de la prestation que votre cocontractant s’engage à accomplir (ex : livrer 50 tonnes d’acier à votre entreprise de soudure), celui-ci est tenu à un certain degré d’obligation au niveau de la réussite de sa prestation.</p>
<p>Lee principe directeur du degré d’obligation de réussite dans un contrat dépend du type de contrôle que le contractant possède sur les facteurs affectant la réussite de l’exécution du contrat. On compte 3 degrés d’obligations qui affecteront vos recours à son endroit en cas d’inexécution :</p>
<p>Obligation de moyens : Il s’agit du plus faible degré d’obligation de réussite d’un contrat. Le cocontractant ne s’engage qu’à prendre tous les moyens possibles pour réussir la prestation à laquelle il s’engage, <strong>sans toutefois garantir son accomplissement</strong>. Le meilleur exemple de ce type d’obligation est celle qui lie le médecin et son patient : il n’a pas l’obligation de garantir une guérison, seulement de prendre tous les moyens pour y parvenir.</p>
<p>Vous comprendrez donc que poursuivre un cocontractant qui n’a pas respecté son engagement est plus difficile lorsque l’obligation n’était que de moyens, puisque vous devrez prouver qu’il n’a pas pris tous les meilleurs moyens pour parvenir à la fin convenue. Ce n’est pas chose facile, c’est le moins qu’on puisse dire!</p>
<p>Obligations de résultat : L’obligation de résultat est plus intense que celle de moyens, puisqu’elle exige du cocontractant qu’il parvienne au résultat initialement convenu. La prestation étant préalablement claire et déterminée, la personne tenue de l’exécuter sauf en cas de raisons justifiant légalement son inexécution, comme un cas de force majeure, par exemple. </p>
<p>Obligation de garantie : L’obligation de garantie est similaire à l’obligation de résultat dans le sens que le résultat final doit absolument être atteint. La nuance est qu’aucune circonstance, aussi fortuite soit-elle ne peut justifier l’inexécution du contrat. Même un cas de force majeur oà les circonstances étaient imprévisibles, irrésistibles et non-causées par le cocontractant ne déchargera ce dernier de son obligation de garantie. Il se porte personnellement garant de l’exécution du contrat dans son entièreté!</p>
<p><strong> </strong></p>
<p><strong>Entente légale vs illégale :</strong> Si l’exécution forcée d’un contrat est possible dans la quasi-totalité des situations, sachez qu’elle ne l’est pas si l’entente que votre entreprise a conclue n’est pas légale. Mais qu’est-ce qui constitue une entente illégale au sens de la loi? Il faut diviser le contrat en deux sous-sections pour obtenir la réponse :</p>
<p><strong>Objet du contrat illégal : </strong>: L’objet du contrat est ce qui se trouve au centre de l’entente conclue. Par exemple, la maison qui est au cœur du contrat de vente de propriété. Alors que la maison constitue un objet de contrat légal, la même chose ne pourrait être dite pour la vente d’un produit illégal, banni ou prohibé par la loi. Pour cette raison, aucun contrat de vente de drogues n’est susceptible d’être exécuté de force, car l’objet du contrat rend ce dernier invalide.</p>
<p>Ainsi, si votre entreprise s’engage à recevoir, de la part d’un tiers, un produit illégal, ce contrat ne serait pas valable légalement, en plus d’être frappé de nullité.</p>
<p><strong>Cause du contrat illégal :</strong> La cause du contrat réfère à la raison qui a poussé les parties à conclure l’entente à l’origine. Par exemple, dans le cas d’un contrat de vente de voiture, la cause du contrat de l’acheteur est le besoin d’un véhicule, alors que la cause du contrat du vendeur est l’argent qu’il reçoit en retour.</p>
<p>La loi se basant sur le principe que tout contrat doive avoir une cause légale, il est donc normal que si votre entreprise contracte pour des motifs illégaux, votre contrat sera frappé de nullité!</p>
<p><strong>Finalement</strong>, vous aurez bel et bien un recours contre un cocontractant qui refuse d’exécuter la prestation à laquelle il s’est engagé si celle-ci est légale. Mais comment se déroulera cette exécution forcée, exactement? La réponse se trouve ci-bas!</p>
<h2>Comment l’avocat forcera-t-il un cocontractant à s’exécuter?</h2>
<p>L’avocat lui-même ne peut pas forcer l’exécution d’un contrat. C’est par l’intervention de celui-ci, suivi d’un jugement du tribunal que le cocontractant de votre entreprise se verra forcé de respecter son engagement. Mais en tant que gestionnaire d’entreprise, vous êtes très bien placé pour savoir que certains contrats non respectés causent des dommages ou qu’un retard d’exécution rendra la tardive de la prestation obsolète.</p>
<p><StaticImage alt="execution-forcee-recours-droit-affaires-commercial" src="../images/execution-forcee-recours-droit-affaires-commercial.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>N’ayez crainte, la loi est à l’avant-garde de ces situations problématiques et prévoit différents mécanismes de compensation qui entreront en jeu lorsque l’exécution en nature s’avèrera impossible.</p>
<p><strong>Exécution forcée en nature : </strong>Mise en œuvre par l’entremise d’une injonction, l’exécution forcée en nature s’avère pertinente lorsque la simple exécution de la prestation convenue réparerait les dommages causés par l’inexécution initiale. Par exemple, un juge pourrait ordonner à une compagnie de livraison à livrer le produit convenu, si ce défaut d’exécution n’a causé aucun autre dommage justifiant une compensation supplémentaire.</p>
<p><strong>Compensation en dommages :</strong> L’octroi de « dommages-intérêts » se produit lorsque l’exécution en nature est impossible ou causerait des inconvénients disproportionnés. Les dommages seront d’ordre compensatoire si l’inexécution du contrat a causé une perte financière, alors qu’ils seront d’ordre moratoire si l’exécution tardive a causé des dommages autres au cocontractant.</p>
<p>Lorsque vous vous trouvez à la tête d’une entreprise ou parmi ses gestionnaires principaux, il est inévitable que vous vous retrouviez face à un scénario d’inexécution de contrat. Il s’agit d’un problème courant dans tous les domaines du monde des affaires, et c’est en de telles circonstances que l’assistance d’un avocat sera indispensable!</p>
<h2>Mettre fin à un contrat, est-ce possible?</h2>
<p>Tout le monde s’est déjà fait prendre à conclure une entente qu’il ou elle a plus tard regrettée. Que ce soit un mauvais échange de cartes de hockey à l’âge de 10 ans ou un contrat de sous-traitance valant plusieurs centaines de milliers de dollars conclu à la tête d’une entreprise, tous veulent se défaire de ces mauvaises transactions coûte que coûte. Cependant, est-ce possible de se défaire des obligations qui accompagnent un contrat signé en bonne et due forme? Les avocats vous répondront rapidement que non, sauf en cas de circonstances exceptionnelles.</p>
<p>En effet, le principe de droit suivant guide le fonctionnement d’à peu près tous les contrats en droit québécois :</p>
<table>
<tbody>
<tr>
<td>
<p><strong> </strong>Le contrat ne peut être résolu, résilié, modifié ou révoqué que pour les causes reconnues par la loi ou de l’accord des parties.</p>
<p>Article 1439 C.c.Q.</p>
</td>
</tr>
</tbody>
</table>
<p><strong>Mais qu’est-ce qui distingue les différentes façons de mettre fin à un contrat? </strong>Ces dispositions prennent effet soit par l’accord des deux parties au contrat ou sur ordre d’un juge, ce que l’on appelle la résolution judiciaire :</p>
<p>Résolution : La résiliation d’un contrat est la solution appropriée lorsque les parties ont conclu un contrat à exécution instantanée (qui prend place en une seule fois), ce qui permet de remettre les choses dans leur état initial.</p>
<p>Comme la résolution vise à rétablir la situation d’origine, la résolution entraîne donc un effet rétroactif qui cherche à faire comme si le contrat n’avait jamais été formé.</p>
<p>Résiliation : La résiliation diffère de la résolution, puisqu’elle ne s’applique qu’aux contrats à exécution successive, c’est-à-dire, les contrats qui s’exécutent sur une période de temps continue (Ex : un contrat d’entreprise convenu avec un entrepreneur en construction).</p>
<p>Alors que la résolution emporte un effet rétroactif, la résiliation quant à elle, n’a d’effets que dans le moment présent. Elle ne fait que mettre un terme aux obligations de chacun des partis.</p>
<p>Annulation : L’annulation survient lorsqu’un contrat n’a pas été valablement formé. Un principe de l’annulation que peu de gens savent est qu’il s’agit, au même titre que la résolution, d’une façon de rétablir la situation initiale. Cependant, ce qui distingue l’annulation, c’est qu’un contrat frappé de nullité est réputé n’avoir jamais été valable, et donc, n’avoir jamais existé.</p>
<p>Lorsqu’un contrat est frappé de nullité après que les parties aient exécuté leurs prestations respectives, il faudra procéder à la restitution des prestations. Celle-ci se fera soit en nature ou par équivalent, dépendamment de la nature du contrat et de la faisabilité d’une restitution en nature.</p>
<p>Modification : Comme vous pouvez en déduire de la lecture de l’article 1339 C.c.Q., un contrat ne peut pas être modifié sans l’accord des deux parties à celui-ci. Un contrat lie donc les parties pour le meilleur et pour le pire, à moins que les deux parties ne s’entendent pour le modifier. Une exception notable est le principe de réduction des obligations en faveur du débiteur qui peut être octroyée lorsque le créancier n’exécute pas adéquatement sa prestation lors d’un contrat à exécution successive.</p>
<p>Pour répondre à la question posée, il est donc bel et bien possible de mettre fin à un contrat, mais le faire n’a véritablement rien de simple! De plus, même si vous parvenez à faire annuler un contrat liant votre compagnie à une autre, vous aurez perdu du temps, investi de l’argent dans des frais légaux, et ce, pour très peu de gain.</p>
<p><strong>La non-exécution de contrat est parfois une stratégie dans le monde des affaires! </strong>Les dirigeants d’entreprise savent que personne ne veut se livrer à une bataille légale en cour. C’est pourquoi certains dirigeants choisissent de ne pas exécuter leurs obligations contractuelles pour forcer une réduction des obligations. Malgré la mauvaise foi qui sous-entend pareil stratagème, la contrepartie contractuelle choisira souvent de négocier, sachant pertinemment que l’alternative est un procès tout aussi couteux.</p>
<p>Mieux vaut consulter un avocat <strong>avant</strong> de signer un contrat plutôt qu’après : il est beaucoup plus facile de prévenir que de guérir en droit, tout comme dans la vie!</p>
<h2>Disputes entre actionnaires et interprétation de contrat</h2>
<p>Du côté corporatif du droit, il faut savoir que la majorité des entreprises incorporées ont mis en place des conventions d’actionnaires. Un tel contrat vise à prévoir les droits, obligations, responsabilités et devoirs des actionnaires d’une compagnie ou, autrement dit, d’une société par actions.</p>
<p><StaticImage alt="dispute-conflit-actionnaires-convention-avocat" src="../images/dispute-conflit-actionnaires-convention-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Une société par actions est une entité fort complexe qui exige que les droits de tous soient clairement établis. Cependant, aucun contrat n’est aussi clair que les parties ne le croient à sa formation. Même si les conditions semblent être claires comme de l’eau de roche, un actionnaire mécontent trouvera un moyen de troubler ces eaux!</p>
<p>La plupart des conventions d’actionnaires prévoient des mécanismes médiation et d’arbitrage en cas de dispute entre ces derniers. Vous aurez toutefois besoin d’un avocat pour vous assister dans cette démarche d’interprétation contractuelle.</p>
<p>De plus, une des sources fréquentes de dispute entre actionnaires provient d’une convention qui n’est plus à jour dû à une modification de structure ou à un changement dans le rôle des actionnaires impliqués. Si vous êtes impliqué de près ou de loin dans l’administration des aspects légaux de votre entreprise, vous devriez songer à mettre votre convention d’actionnaires à jour avant qu’elle ne devienne source de conflit!</p>
<p><strong>Comment déterminer le sens à donner à un contrat lors du processus d’interprétation?</strong></p>
<p>Que la dispute se retrouve devant un médiateur, un arbitre ou un juge, ce sont les principes d’interprétation de contrat du Code civil du Québec qui seront appliqués.</p>
<p>Cela implique de rechercher l’intention commune des parties au moment de la formation du contrat et non au sens littéral des mots du contrat. C’est le mot d’ordre que le juge tentera d’appliquer le plus fidèlement possible!</p>
<p>Cependant, il est possible que la recherche de cette intention commune amène le juge à regarder dans les circonstances entourant la formation du contrat pour trouver cette intention commune.</p>
<p>Si jamais cette recherche s’avérait infructueuse, le juge serait dans l’obligation de trancher en faveur de la partie au contrat qui a stipulé l’entente et en faveur de celle qui y a adhéré. Cela n’arrivera que lorsque tous les mécanismes d’interprétation auront été épuisés.</p>
<p>Dans tous les cas, vous devez agir le plus rapidement possible et consulter un avocat dès que possible si la chicane entre associés semble se pointer le bout du nez, car un tel conflit nuira à la productivité et la rentabilité de l’entreprise!</p>
<h2>Démarrage d’entreprise</h2>
<p>Les avocats ne sont pas seulement des alliés lorsque le branle-bas de combat s’annonce entre les actionnaires d’une compagnie. Leur expertise est également pertinente lors d’occasions plus joyeuses, comme le démarrage d’une entreprise! Mais comment un avocat peut-il vous aider dans de telles circonstances?</p>
<p><strong>
Conseils légaux : </strong>Signature de contrats, partenariats, entente de paiements : voici des décisions qui font partie du quotidien d’un dirigeant d’entreprise! Cela est d’autant plus vrai lors du démarrage de celle-ci! Avec qui vous associerez-vous? Avez-vous le droit de conclure une certaine entente? Pour avoir la réponse à ces questions, vous aurez besoin des conseils d’un avocat afin de démarrer votre entreprise du bon pied?</p>
<p><strong>Mise en place de la structure : </strong>Les entreprises ne sont pas toutes faites sur le même moule! Dépendamment de la taille de cette dernière, il faudra effectuer un choix de structure d’entreprise qui répond à ses besoins et à ceux de ses dirigeants. Votre avocat vous guidera parmi les choix qui s’offrent à vous, soit l’entreprise individuelle, la société en commandite, en nom collectif et la société par actions. Chacune de ces options entraîne un degré d’obligations et de responsabilités différent pour les personnes impliquées.</p>
<p><strong>Rédaction de convention d’actionnaires : </strong>Si le choix de structure s’arrête sur la société par actions, il sera judicieux de penser à engager un <ConversionLink to="/avocat-redaction-convention-actionnaires/" text="avocat pour rédiger une convention d’actionnaires" button={false}></ConversionLink>. Vous avez vu qu’une dispute entre les actionnaires d’une compagnie peut vite s’envenimer. Prendre les devants en faisant rédiger une convention claire entamera votre aventure entrepreneuriale du bon pied!</p>
<p><strong>L’incorporation : </strong>Si vous avez la chance de faire partie d’une entreprise qui génère un important chiffre d’affaires tout en multipliant les partenariats stratégiques, vous voudrez songer à l’incorporation. Si ce processus se passe parfois en cours de route au moment où l’entreprise prend une ampleur notable, il est également possible d’incorporer une entreprise qui vient tout juste de voir le jour. Cela s’avèrera prudent si vous opérez dans un domaine où les poursuites en responsabilité civile sont fréquentes.</p>
<p>Pour dire les choses simplement, engager un avocat pour démarrer votre entreprise vous permet de commencer du bon pied en évitant de piler sur les pieds de vos compétiteurs illégalement ou de simplement faire un faux pas.</p>
<h2>Perception de comptes et marchandise non livrée</h2>
<p>Une fois votre entreprise bien rodée et vos relations d’affaires solidement en place, un des défis de votre quotidien à la tête d’une entreprise sera de faire respecter les ententes conclues. Si vous croyez que tous vos associés se sentiront liés par le contrat conclu, détrompez-vous! Même des partenaires de longue date peuvent s’avérer de véritables troubles fête lorsqu’ils refusent d’exécuter leurs prestations. Vous devez donc savoir quoi faire pour les forcer à remplir leurs obligations!</p>
<p><StaticImage alt="perception-comptes-marchandises-non-livree-avocat" src="../images/perception-comptes-marchandises-non-livree-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Pour ce qui est de <strong>la perception de comptes</strong> (se faire payer pas ses créanciers), elle nécessite l’intervention d’un avocat, puisqu’il faudra mettre l’autre partie en demeure pour lui demander de payer les sommes dues, et ce, avant d’entamer quelconque poursuite à son endroit.</p>
<p>Une mise en demeure se doit d’être claire, d’énoncer l’action dont vous voulez forcer l’autre personne à exécuter et indiquer un délai dans lequel les recours légaux seront pris. Un tel document est non seulement mieux rédigé par un avocat compétent, mais sera également plus susceptible de convaincre l’autre partie à s’exécuter. Si la mise en demeure ne fonctionne pas, vous aurez toujours les recours mentionnés plus haut à votre disposition!</p>
<p>D’ailleurs, la même chose peut être dite <strong>des marchandises non livrées</strong> que vous avez déjà payées! Vous devrez mettre en demeure votre fournisseur de marchandise, ce qui ouvrira les mêmes portes que pour une perception de comptes.</p>
<p>Une chose est certaine, les outils légaux mis à votre disposition pour faire respecter vos droits ont leur raison d’être et sont toujours plus efficaces que de faire durer le conflit a vitam aeternam.</p>
<h2>Fusions et acquisitions : ne pas négliger les vérifications diligentes!</h2>
<p>Souvent vues comme les opérations les plus spectaculaires du monde corporatif, les fusions et acquisitions n’ont en principe, rien de compliqué. En pratique, toutefois, c’est tout le contraire. La fusion consiste à prendre deux entreprises aux intérêts communs ou complémentaires et à les faire renaître sous un même toit.</p>
<p>Vous aurez deviné qu’une fusion de deux grandes entreprises nécessitera une réorganisation et une restructuration du fonctionnement de l’entreprise. Cela inclut de rédiger une nouvelle convention d’actionnaires, de redéfinir la valeur de certaines actions et, parfois, de mettre des employés à pied. En effet, comme une fusion créée parfois une répétition non nécessaire de certains postes, le congédiement est parfois inévitable pour certains.</p>
<p>L’acquisition, bien qu’entraînant également des démarches légales et administratives exhaustives, est légèrement plus simple que la fusion. Elle consiste à conclure l’achat d’une entreprise par une autre. Cependant, il y a plus de complexité à ces deux processus qu’il n’y parait à première vue. En effet, tout doit d’abord commencer par les vérifications diligentes par un avocat en droit commercial!</p>
<p>Les <strong>vérifications diligentes,</strong> comme leur nom le dit si clairement, consistent à analyser tous les aspects importants d’une compagnie faisant potentiellement l’objet d’un achat. Ces aspects incluent les finances de la compagnie, les dettes contractées à l’égard de divers créanciers, des risques de poursuites diverses et surtout la profitabilité projetée de l’entreprise en question.</p>
<p>Le juriste dressera également la liste des actifs de l’entreprise, des droits réels qu’elle possède ainsi que des différentes hypothèques qui pourraient être grevées à l’entreprise. Il fera également une analyse complète des contrats qui lient l’entreprise du vendeur à des tiers pour déterminer l’étendue des obligations achetées.</p>
<p>Le rôle de l’avocat corporatif, dans tout ce branle-bas de combat, est de s’assurer que la transaction soit sécuritaire d’un aspect légal et financier pour l’acheteur. Cela peut paraître simple, mais les critères d’une transaction sécuritaire ne sont pas les mêmes dans le secteur de l’agriculture que dans le domaine de la propriété intellectuelle.</p>
<p>Même si les principes sous-jacents des fusions et acquisitions sont assez stables, la diversité des domaines où ces principes s’appliquent fait en sorte que les avocats doivent savoir s’adapter aux particularités à chacun des secteurs du monde des affaires.</p>
<h2>Représentation en cours et règlement de différend</h2>
<p>Certains avocats ne vivent que pour la plaidoirie, alors que d’autres passent au travers d’une carrière au complet sans avoir mis les pieds dans une cour. Ce sont deux approches différentes au droit qui ont chacun leurs mérites. Si certaines situations se rendent jusqu’en cour avec raison, d’autres seraient mieux servies avec un règlement hors cour.</p>
<p><StaticImage alt="reglement-differend-hors-cours-negociation-arbitrage" src="../images/reglement-differend-hors-cours-negociation-arbitrage.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Cela est notamment le cas pour les entreprises de toutes tailles!</strong> Les jugements rendus par un juge étant souvent insatisfaisant pour tous les partis impliqués, il vaut mieux s’entendre hors cours pour tente d’en venir à un réel compromis!</p>
<p>D’ailleurs, ce n’est pas un secret que l’accessibilité aux tribunaux de justice est un problème criant de notre société, ce qui fait en sorte qu’obtenir justice est un réel défi. Cela est vrai à un point tel que le nouveau Code de procédure civile du Québec (livre régissant les procédures à suivre dans notre système de droit), prévoit dorénavant une obligation pour les potentiels partis à une dispute devant tribunaux d’avoir au moins tenté un règlement de différent avant de déposer une demande introductive d’instance :</p>
<table>
<tbody>
<tr>
<td>
<p>Les modes privés de prévention et de règlement des différends sont choisis d’un commun accord par les parties intéressées, dans le but de prévenir un différend à naître ou de résoudre un différend déjà né.</p>
<p>Ces modes privés sont principalement la négociation entre les parties au différend de même que la médiation ou l’arbitrage dans lesquels les parties font appel à l’assistance d’un tiers. Les parties peuvent aussi recourir à tout autre mode qui leur convient et qu’elles considèrent adéquat, qu’il emprunte ou non à ces modes.</p>
<p><strong>Les parties doivent considérer le recours aux modes privés de prévention et de règlement de leur différend avant de s’adresser aux tribunaux.</strong></p>
<p>Article 1
Code de procédure civile du Québec</p>
</td>
</tr>
</tbody>
</table>
<p>Il s’agit d’une initiative prise par le législateur qui vise à désengorger les tribunaux et favoriser les règlements de disputes de façon privée plutôt que par l’entremise de la justice publique. Mais en quoi consistent la médiation, la négociation et l’arbitrage?</p>
<p><strong>Négociation :</strong> Par l’entremise d’avocats représentant chacun des partis (dans ce cas-ci, les entreprises au cœur d’un différend), on cherchera à conclure une entente qui satisfera les intérêts de chacun des parties concernées. Aucun tiers n’entre dans la résolution de ce conflit à part les personnes directement impliquées et leurs représentants légaux. La négociation est d’ailleurs souvent la première étape de résolution de différend.</p>
<p><strong>La médiation : </strong> lorsque la négociation s’avère infructueuse ou qu’une telle option ne s’est jamais présentée, le recours à la médiation est un réflexe courant en droit. La médiation consiste en fait à une négociation supervisée par un tiers; le médiateur. Celui-ci jouera l’entremetteur en tentant de concilier les intérêts des parties pour leur faire conclure une entente qui leur convient à tous. Le médiateur n’a pas de pouvoir décisionnel autre que de faire de son mieux pour assister les parties à en venir à une entente.</p>
<p><strong>L’arbitrage :</strong> Contrairement à la médiation, l’arbitrage consiste à remettre le pouvoir décisionnel qu’aurait normalement un juge entre les mains d’un tiers. Lorsque les deux parties s’entendent pour soumettre leur différend à l’arbitrage, ils acceptent d’être liés par la décision de l’arbitre, en plus de renoncer à leur droit d’amener ce même problème en cour. L’arbitre est donc investi d’importants pouvoirs décisionnels, d’autant que sa décision est sans appel, contrairement à la décision d’un juge. Ce dernier doit également avoir été choisi par les deux partis.</p>
<p><strong>Mais quels sont les avantages de régler les problèmes de votre entreprise avec un mode privé de règlement de différend?</strong> L’avantage premier est que la dispute se règlera beaucoup plus rapidement. En effet, en l’espace de quelques semaines, vous pourrez régler le dossier du tout et passer aux choses plus importantes sans tarder.</p>
<p>Au contraire, une dispute qui est amenée en cour peut prendre plusieurs années à se régler, ce qui vous coûtera d’importants frais d’avocats en plus de vous faire perdre énormément de temps.</p>
<p><strong>Mais comme rien n’est jamais parfait, quels sont les désavantages du domaine privé?  </strong>La décision sans appel est un principe des règlements privés que beaucoup déplorent. Comme la décision est finale et que l’arbitre a un pouvoir décisionnel presque illimité, les résultats injustes sont difficiles à corriger. Il faut toutefois garder en tête que les deux entreprises impliquées ont accepté volontairement de procéder de la même façon, alors d’un autre côté, tout le monde prend le même risque.</p>
<p>Comme dans tous les secteurs privés d’activité, les coûts rattachés sont bien plus importants que dans le domaine public. En effet, les services d’un médiateur ou d’un arbitre ne sont pas donnés, c’est le moins qu’on puisse dire.</p>
<p>Toutefois, pour une entreprise d’une certaine taille, ces coûts ne sont que minime comparé aux mois d’attentes que leur fera subir la justice publique.</p>
<h2>Comment choisir votre avocat en droit des affaires? Questions fréquentes à poser!</h2>
<p>Les avocats ont accès à des données du plus haut degré de confidentialité en ce qui a trait à votre entreprise et ses activités. Vous devez donc savoir qui engager et comment procéder à la sélection du bon avocat. Voici donc nos conseils en la matière avec quelques questions fréquentes que vous devriez poser pour tester les compétences de votre avocat:</p>
<p><StaticImage alt="comment-trouver-avocat-affaires-commercial-questions" src="../images/comment-trouver-avocat-affaires-commercial-questions.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Critères de sélection :</strong></p>
<p><strong>Compétence dans votre domaine d’affaires</strong> : Un juriste peut bien se qualifier « d’avocat d’affaires », cela n’implique pas pour autant qu’il s’y connait dans votre domaine d’expertise. Assurez-vous donc qu’il possède au moins les connaissances de base relatives à votre secteur d’affaires avant de lui remettre les rennes des aspects légaux de votre compagnie.</p>
<p><strong>Communication claire entre vous : </strong>Un avocat qui vous lance le jargon juridique en plein visage à chaque conversation que vous avez avec lui ne sert pas vos meilleurs intérêts. Dans le pire des scénarios, celui-ci pourrait même essayer de vous noyer dans un langage que vous ne connaissez pas pour son propre bénéfice. Soyez sûr que vous comprenez tout ce que votre avocat vous dit, peu importe le niveau de complexité légale du dossier.</p>
<p><strong>Partage d’une philosophie commune :</strong> L’avocat aura à prendre de nombreuses décisions en votre nom (avec votre accord bien sûr) et il agira ainsi à titre de conseiller à bien des occasions. C’est là la raison primaire qui fait que vous devez partager la même philosophie d’affaires avec votre avocat, sans quoi la confiance nécessaire à la conduite de bonnes affaires ne prendra jamais racine.</p>
<p><strong>Réputation connue du juriste : </strong>Le droit est un monde où la réputation en dit long sur un professionnel. C’est un domaine dans lequel les bons comme les mauvais coups ne passent pas inaperçus. Ça ne devrait donc pas être complexe de découvrir la réputation qui précède votre potentiel avocat, qu’elle soit bonne ou mauvaise!</p>
<p><strong>Questions fréquentes : </strong></p>
<p><strong>Quelle est votre expérience dans le domaine du droit des affaires?</strong></p>
<p>A-t-il procédé à des transactions d’envergure comme des fusions et acquisitions, des procès de litiges commerciaux d’importance ou encore des restructurations d’entreprise à grande échelle? Bref, vous cherchez à découvrir ce qui rend votre expert compétent dans son domaine, le rendant ainsi utile dans le vôtre!</p>
<p><strong>Travaillez-vous seul ou avec d’autres experts de domaines connexes?</strong></p>
<p>Que votre avocat travaille seul, en contentieux ou dans le plus grand cabinet de la métropole, un des aspects les plus importants à vérifier est s’il s’associe à des professionnels d’une expertise connexe ou complémentaire.</p>
<p>Les aspects légaux d’une entreprise s’entremêlent souvent à la finance et la comptabilité de celle-ci. Il est donc normal qu’un avocat s’entoure d’experts qui savent contribuer et ajouter leur grain de sel à quelconque démarche vous tentez d’accomplir.</p>
<p><strong>Comment fonctionnent vos honoraires et à quel prix offrez-vous vos services?</strong></p>
<p>Prix fixe, taux horaire, compensation en cas de gain, etc. Il existe de nombreuses façons qu’un avocat peut être rémunéré pour ses services. C’est toutefois une question que vous devez absolument lui poser avant de signer le contrat vous liant à lui.</p>
<p>La Loi sur le barreau ainsi que le Code de déontologie des avocats obligent ces derniers à charger des honoraires raisonnables aux vues et sues des circonstances, en plus justifier leur prix par la complexité et la gravité du dossier. Prenez également note que vous avez un recours de contestation à votre disposition si jamais vous jugez que les honoraires de votre avocat sont déraisonnables.</p>
<p><strong>Comment communiquez-vous l’évolution du dossier avec vos clients?</strong></p>
<p>Dans le monde des affaires, les choses évoluent et changent rapidement, c’est le moins que l’on puisse dire. Comme vous avez un rôle à jouer dans la prise de décisions en tant que gestionnaire d’entreprise, il est essentiel que vous soyez mis au courant des plus récents développements légaux par votre avocat. Demandez donc à votre juriste de choix à propos du mode de communication qu’il utilise normalement pour assurer une compréhension claire de tous les partis.</p>
<h2>L’avocat en droit des affaires : l’allié des dirigeants d’entreprise!</h2>
<p>L’erreur que beaucoup trop de dirigeants d’entreprise font est de penser qu’ils en savent assez sur leur domaine pour gérer les aspects légaux. Toutefois, bien qu’un dirigeant puisse connaître son domaine de pratique sur le bout des doigts, le monde du droit, quant à lui, est une tout autre bête!</p>
<p><StaticImage alt="avocat-cabinet-droit-affaires-entreprises-conseils-juridiques" src="../images/avocat-cabinet-droit-affaires-entreprises-conseils-juridiques.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>C’est pourquoi, peu importe le type d’affaires que mène votre entreprise, vous aurez inévitablement besoin de l’assistance d’un avocat en droit des affaires et en droit commercial. Cela optimisera le rendement de votre entreprise, en plus la protéger contre d’éventuelles poursuites!</p>
<p><strong>Pour comparer les différentes offres de services des avocats dans votre région, vous n’avez qu’à remplir le formulaire en bas de page pour recevoir gratuitement 3 soumissions gratuites.</strong></p>
<p><strong>Le service de Soumissions Avocat est gratuit, simple, et ne vous engage à rien, alors contactez-nous!</strong></p>
    </SeoPage>
)
export default ContentPage21
  